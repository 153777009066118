<template>
    <inner-page-card heading="Bidder Details" :link-back="true" class="mt-3">

        <div v-if="loading" class="fl-eq">
            <loading-animation/>
        </div>

        <div v-else>
            <table class="table table-bordered border-r-2 mb-3">
                <thead>
                <tr class="bg-4">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            Bidder Id - {{ details.user_id }}
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <!--                &#45;&#45;responsive-->
                <div class="d-lg-none">
                    <tr class="font-inter-medium w-50" colspan="1">
                        <div class="fl-x fl-a-c">
                        <span class="pl-3 mt-4">
                            <img src="../../assets/web/super-admin/bidders/hi.png" style="height: 2rem;" alt="">
                        </span>
                            <span class="font-inter-semi-bold fs-lg-3 fs-2 mt-4 pl-3">Total Participation : </span>
                            <span class="text-primary font-inter-semi-bold mt-4 fs-lg-3 fs-2 pl-3">{{
                                    details.total_participation
                                }}</span>
                        </div>
                    </tr>
                    <tr class="font-inter-medium" colspan="1">
                        <div class="fl-x fl-a-c">
                        <span class="pl-3 mt-3 mb-3">
                            <img src="../../assets/web/super-admin/bidders/trpohy.png" style="height: 2rem;" alt="">
                        </span>
                            <span class="font-inter-semi-bold fs-lg-3 fs-2 pl-3 mt-3 mb-3">Total Wins : </span>
                            <span class="text-primary font-inter-semi-bold fs-lg-3 fs-2 pl-3 mt-3 mb-3">{{
                                    details.total_wins
                                }}</span>
                        </div>
                    </tr>
                </div>
                <!--                    //web//-->
                <div class="d-sm-none">
                    <tr class="w-100 fl-x">
                        <td class="font-inter-medium w-100" colspan="1">
                            <div class="fl-x fl-a-c">
                        <span class="pl-3 mt-4">
                            <img src="../../assets/web/super-admin/bidders/hi.png" style="height: 2rem;" alt="">
                        </span>
                                <span class="font-inter-semi-bold fs-lg-3 fs-2 mt-4 pl-3">Total Participation : </span>
                                <span class="text-primary font-inter-semi-bold mt-4 fs-lg-3 fs-2 pl-3">{{
                                        details.total_participation
                                    }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium w-100" colspan="1">
                            <div class="fl-x fl-a-c">
                        <span class="pl-3 mt-3 mb-3">
                            <img src="../../assets/web/super-admin/bidders/trpohy.png" style="height: 2rem;" alt="">
                        </span>
                                <span class="font-inter-semi-bold fs-lg-3 fs-2 pl-3 mt-3 mb-3">Total Wins : </span>
                                <span class="text-primary font-inter-semi-bold fs-lg-3 fs-2 pl-3 mt-3 mb-3">{{
                                        details.total_wins
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </div>
                </tbody>
            </table>

            <table class="table table-bordered border-r-2  mb-0">
                <thead>
                <tr class="bg-4 ">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2 ">
                            Bidder Details
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <tr>
                    <td class="font-inter-medium w-50p">
                        <div class="">
                            <span>Registration type : </span>
                            <span>{{ details.customer_type }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium ">
                        <div class="">
                            <span>First Name : </span>
                            <span>{{ details.first_name }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div>
                            <span>Last Name : </span>
                            <span>{{ details.last_name }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium d-sm-none-lg-block">
                        <div>
                            <span>Email : </span>
                            <br class="d-lg-none"/>
                            <div class="fs--1 pos-r" style="inline-size:60px !important; display: inline-block;">
                                {{ details.email }}
                            </div>
                            <!--                            <span class="fs&#45;&#45;1 pr-2" v-html="details.email" style="inline-size:30px !important;"></span>-->
                        </div>
                    </td>
                    <td class="font-inter-medium d-lg-none">
                        <div>
                            <span>Father's Name : </span>
                            <span>{{ details.father_husband_name }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="font-inter-medium d-lg-none-sm-inline">
                        <div>
                            <span>Email : </span>
                            <br class="d-lg-none"/>
                            <span class="fs--1 pos-r" style="inline-size:60px !important;">{{ details.email }}</span>
                            <!--                            <span class="fs&#45;&#45;1 pr-2" v-html="details.email" style="inline-size:30px !important;"></span>-->
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div>
                            <span>Mobile : </span>
                            <span>{{ details.mobile_number }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium d-sm-none-lg-block">
                        <div>
                            <span>Father's Name : </span>
                            <span>{{ details.father_husband_name }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium d-lg-none">
                        <div>
                            <span>Country : </span>
                            <br/>
                            <span>{{ details.country }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium d-sm-none-lg-block">
                        <div>
                            <span>Country : </span>
                            <span>{{ details.country }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium d-lg-none">
                        <div>
                            <span>District : </span>
                            <span>{{ details.district }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>State : </span>
                            <span>{{ details.state }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium d-sm-none-lg-block">
                        <div>
                            <span>District : </span>
                            <span>{{ details.district }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>Pincode : </span>
                            <span>{{ details.pincode }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium d-lg-none">
                        <div>
                            <span>PAN Number : </span>
                            <span>{{ details.pan }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium d-sm-none-lg-block">
                        <div>
                            <span>PAN Number : </span>
                            <span>{{ details.pan }}</span>
                        </div>
                    </td>
<!--                    <td class="font-inter-medium" colspan="2">-->
<!--                        <div>-->
<!--                            <span>Uploaded Documents : &nbsp;&nbsp; </span>-->
<!--                            <btn text="View Documents" class="ml-2" size="xs"-->
<!--                                 @click="$refs.uploadedDocsModal.show()"></btn>-->
<!--                        </div>-->
<!--                    </td>-->
                </tr>
<!--                <tr>-->
<!--                    <td colspan="2" class="font-inter-medium">-->
<!--                        Address: {{ details.address }}-->
<!--                    </td>-->
<!--                </tr>-->
                </tbody>
            </table>
            <modal class="c-modal c-p-0 no-heading no-close" :no-close-on-backdrop="false" title=""
                   ref="uploadedDocsModal" width="40r"
                   header-color="">
                <table class="table table-bordered border-r-2 mb-0">
                    <thead>
                    <tr class="bg-4">
                        <th rowspan="1" colspan="2" class="pos-r">
                            <div class="font-inter-medium text-center fs-lg-2">
                                Uploaded Documents
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="section-items">
                    <tr>
                        <td class="font-inter-medium">
                            <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>EMD Form</span> <span>:</span></span>
                                <br class="d-lg-none"/>
                                <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="details.emd_payment_form"
                                         @click="setDownload(details.emd_payment_form)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>EMD Payment Receipt</span> <span>:</span></span>
                                <br class="d-lg-none"/>
                                <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="details.emd_payment_receipt"
                                         @click="setDownload(details.emd_payment_receipt)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Proof Of Address</span> <span>:</span></span>
                                <br class="d-lg-none"/>
                                <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="details.proof_of_address"
                                         @click="setDownload(details.proof_of_address)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Pancard</span> <span>:</span></span>
                                <br class="d-lg-none"/>
                                <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="details.pancard"
                                         @click="setDownload(details.pancard)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Declaration Form</span> <span>:</span></span>
                                <br class="d-lg-none"/>
                                <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="details.declaration_form"
                                         @click="setDownload(details.declaration_form)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </modal>
        </div>
    </inner-page-card>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'BidderDetailsPage',

    data () {
        return {
            loading : true,
            details : [],
            id      : this.$route.params.id
        };
    },

    mounted () {
        this.loadDetails();
    },

    methods : {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.userList.biddersList.info, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.loading = false;
                console.log('json.data', json.data);
            } else {
                this.loading = false;
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        },

        setDownload (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped lang="scss">
@media (max-width: 750px) {
    .w-50p {
        width: 50%;
    }
}

.d-flex-w50 {
    @media (min-width: 992px) {
        display: flex;
        width: 50%;
    }
}

</style>
